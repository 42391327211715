import React, { useEffect, useState } from 'react';
import BG from "./components/bg.svg";
import styled from 'styled-components';
import CR from "./components/character.svg";
import Cass from "./components/logo.svg";
import { useLocation, useNavigate } from 'react-router-dom';

const Section = styled.div`
  background-image: url(${BG});
  background-size: cover;
  height: calc(100vh - 4px);
  padding: 0px 10px;
  max-width: 500px;
  margin: auto;
  border-bottom: 4px solid; /* Width of the border */
  border-image: linear-gradient(to right, #0075CD, #8BCDFF) 1; /* Apply gradient */
  z-index: 1000;

`;

const Character = styled.img`
  display: block;
  margin: auto;
  width: 100%;
`

const Logo = styled.img`
  display: block;
  margin: auto;
  padding: 10px 0px;
`

const H1 = styled.h1`
  font-family: "Gotham-Thin";
  text-align: center;
  color: white;

  span{
    font-family: "Gotham-Ultra";
  }
`
const Anchor = styled.a`
  background-color: #0075CD;
  color: white;
  text-decoration: none;
  padding: 15px 25px;
  margin: auto;
  display: block;
  text-align: center;
  width: 70%;
  font-family: "Gotham-Medium";
  border-radius: 8px;
  border: 2px solid #8BCDFF;
  animation: pulse 1s infinite;

  @keyframes pulse {
  0% {    transform: scaleX(1)  }
  50% {    transform: scale3d(1.05, 1.05, 1.05)  }
  to {    transform: scaleX(1)  }
  }
`
const AnchorDiv = styled.div`
  margin: 20px auto;
`


function App() {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = queryParams.get('_token');
  const [token, setToken] = useState<string | null>(tokenFromUrl);

  useEffect(() => {
    if (!token) {
      const newToken = 'uuid_1r7e07538sud_1r7e07538sud667319a6852cf5.75174256';
      setToken(newToken);

      queryParams.set('_token', newToken);
      navigate(`${window.location.pathname}?${queryParams.toString()}`, { replace: true });
    }
  }, [token, navigate, window.location.pathname, window.location.search]);
  return ( 
    <>
    <Section>
      <div>
        <Logo src={Cass} alt="" />
      </div>
      <div>
        <H1>
          GANHE <span>100 RODADAS GRÁTIS + R$ 50 DE BÔNUS</span> NA PLATAFORMA POR <span>R$ 50</span>
        </H1>
      </div>
      <AnchorDiv>
        <Anchor href={`https://rodadascpx.com/?_lp=1&_token=${token}`}>RESGATE SUAS RODADAS</Anchor>
      </AnchorDiv>
      <div>
        <Character src={CR} alt="" />
      </div>
    </Section>
    </>
  );
}

export default App;
